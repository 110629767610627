import { graphql } from 'gatsby';

export const ColorFragment = graphql`
  fragment Color on SanityColor {
    title
    value
  }
`;

type ColorTitle = 'black' | 'darkGreen' | 'green' | 'yellow' | 'lightGrey' | 'beige' | 'white';

export type Color =
  | '#072026'
  | '#02443C'
  | '#A8AD81'
  | '#D6FF4F'
  | '#F5F5F5'
  | '#DBD8D2'
  | '#FFFFFF';

export interface ColorObject {
  title: ColorTitle;
  value: Color;
}

export function getColor(colorTitle: ColorTitle): ColorObject {
  const colorByColorTitle = {
    black: { title: 'black', value: '#072026' },
    darkGreen: { title: 'darkGreen', value: '#02443C' },
    green: { title: 'green', value: '#A8AD81' },
    yellow: { title: 'yellow', value: '#D6FF4F' },
    lightGrey: { title: 'lightGrey', value: '#F5F5F5' },
    beige: { title: 'beige', value: '#DBD8D2' },
    white: { title: 'white', value: '#FFFFFF' },
  } as const;
  return colorByColorTitle[colorTitle];
}

export function getColorValueByColorTitle(colorTitle: ColorTitle): Color {
  const colorValueByColorTitle = {
    black: '#072026',
    darkGreen: '#02443C',
    green: '#A8AD81',
    yellow: '#D6FF4F',
    lightGrey: '#F5F5F5',
    beige: '#DBD8D2',
    white: '#FFFFFF',
  } as const;
  return colorValueByColorTitle[colorTitle];
}
